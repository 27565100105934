import Showcase from './Showcase.vue'
import ShowcaseOneBtn from './ShowcaseOneBtn.vue'
import ShowcaseV2 from './ShowcaseV2.vue'
import ShowcaseStarzaym from './ShowcaseStarzaym.vue'
import ShowcaseFilters from './ShowcaseFilters.vue'


export default {
    'v-showcase-default': Showcase,
    'v-showcase-OneBtn': ShowcaseOneBtn,
    'v-showcase-v2': ShowcaseV2,

    'v-showcase-starzaym': ShowcaseStarzaym,


    //filters
    'v-showcase-filter-default': ShowcaseFilters,

}