<template>
  <div class="showcase-wrapper vamzaim">
    <div class="card" v-for="offer in offers">
 
      <div class="card-wrapper">
        <div class="card-logo">
          <img :src="'https://365money.ru' + offer.logo" alt="" />
        </div>
        <div class="card-info">
          <div class="card-info__rate">
            <star-rating :max="5" :current="offer.rating"></star-rating>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__logo">
              <img src="/img/rub1.svg" alt="" />
            </div>
            <div class="card-info__wrapper__value">
              до {{ numberFormat(offer.amount) }} ₽
            </div>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__logo">
              <img src="/img/date1.svg" alt="" />
            </div>
            <div class="card-info__wrapper__value">
              до {{ offer.period }} дней
            </div>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__logo">
              <img src="/img/perc1.svg" alt="" />
            </div>
            <div class="card-info__wrapper__value">
              от {{ toFixed(offer.percent) }}%
            </div>
          </div>
          <div class="card-btn">

            <button @click="openLinks(offer)">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.849513 9.59511C0.894214 10.6375 1.07389 11.3714 1.47773 12.161C2.26239 13.695 3.68935 14.8045 5.35648 15.1765C5.97863 15.3154 6.30339 15.3334 8.18496 15.3335C10.061 15.3336 10.3476 15.3175 10.9822 15.1763C13.1721 14.6891 14.9204 12.9151 15.3623 10.7319C15.4841 10.1301 15.4994 9.82957 15.5001 8.0149C15.5009 6.13193 15.4831 5.80759 15.3443 5.18553C14.8557 2.99489 13.0818 1.24569 10.9011 0.803981C10.2959 0.681382 10.0016 0.666613 8.16932 0.666832C6.3384 0.66705 6.03944 0.682071 5.43753 0.803981C3.72454 1.15094 2.21907 2.32367 1.44286 3.91576C1.14005 4.53686 0.958247 5.16106 0.882327 5.84017C0.838721 6.23037 0.816136 8.81611 0.849513 9.59511ZM4.97538 10.6746C5.13567 11.0209 5.58255 11.174 5.91191 10.9954C5.98333 10.9566 6.95783 10.0753 8.07748 9.03671C9.19716 7.99816 10.1175 7.1533 10.1228 7.15925C10.128 7.16519 10.1459 7.74186 10.1625 8.44074C10.1916 9.66185 10.1955 9.71711 10.263 9.8567C10.5129 10.373 11.2351 10.3809 11.4962 9.87028L11.5864 9.69383L11.534 7.64967C11.4928 6.04741 11.4715 5.57432 11.4353 5.46126C11.3506 5.19654 11.0567 4.93219 10.7969 4.88707C10.4242 4.82233 6.7835 4.47209 6.69404 4.49236C6.22407 4.59882 6.00163 5.07497 6.22344 5.49972C6.28582 5.61913 6.3526 5.69291 6.45645 5.75715C6.59828 5.84486 6.63476 5.85059 7.85238 5.97666C8.54003 6.04788 9.1117 6.11519 9.12274 6.12626C9.13375 6.13734 8.22037 7.00144 7.093 8.04651C5.55321 9.47385 5.02743 9.9793 4.97967 10.078C4.89871 10.2452 4.89687 10.505 4.97538 10.6746Z" fill="white"/>
              </svg>
              </div>
              <div>ВЫБРАТЬ</div>
              
              
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Functions from "../assets/js/functions.js";
import * as Data from "../assets/js/data_params.js";
import StarRating from "@/components/StarRating";
export default {
  name: "ShowcaseVamZaim",
  components: {
    StarRating,
  },
  props: {
    offers: Array,
  },

  data: () => ({
    ratingText: Data.getRatingText(),
    buttonText: Data.getButtonText(),
  }),

  methods: {
    //Форматирование сумм
    numberFormat(num) {
      return Functions.numberFormat(num);
    },

    //Добавить нули после запятой
    toFixed(num) {
      return num.toFixed(1);
    },
    openLinks(offer) {
      window.open(offer.link, "_blank");
      offer.links.forEach((link) => {
        window.open(link, "_blank");
      });
    },
  },
};
</script>
<style lang="css">
@import url(../assets/css/vamzaim.scss);
</style>
<style lang="css">
.star-rating {
  width: 50%;
}
.card-info__wrapper {
  border-radius: 23.226px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  padding: 5px;

}

.card-info__rate {
  margin-left: 30px;
    margin-bottom: 15px;
    width: 100%;
}
</style>
