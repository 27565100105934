<template>
  <div class="showcase-wrapper starzaym">
    <div class="card" v-for="offer in offers">
      <img src="img/scale.svg" alt="" class="scale" />
      <div class="card-wrapper">
        <div class="card-logo">
          <img :src="'https://365money.ru' + offer.logo" alt="" />
        </div>
        <div class="card-info">
          <div class="card-info__rate">
            <star-rating :max="5" :current="offer.rating"></star-rating>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__logo">
              <img src="/img/rub.svg" alt="" />
            </div>
            <div class="card-info__wrapper__value">
              до {{ numberFormat(offer.amount) }} ₽
            </div>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__logo">
              <img src="/img/date.svg" alt="" />
            </div>
            <div class="card-info__wrapper__value">
              до {{ offer.period }} дней
            </div>
          </div>
          <div class="card-info__wrapper">
            <div class="card-info__wrapper__logo">
              <img src="/img/perc.svg" alt="" />
            </div>
            <div class="card-info__wrapper__value">
              от {{ toFixed(offer.percent) }}%
            </div>
          </div>
          <div class="card-btn">
            <button @click="openLinks(offer)">
              ПОЛУЧИТЬ ЗАЕМ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Functions from "../assets/js/functions.js";
import * as Data from "../assets/js/data_params.js";
import StarRating from "@/components/StarRating";
export default {
  name: "ShowcaseStarzaym",
  components: {
    StarRating,
  },
  props: {
    offers: Array,
  },

  data: () => ({
    ratingText: Data.getRatingText(),
    buttonText: Data.getButtonText(),
  }),

  methods: {
    //Форматирование сумм
    numberFormat(num) {
      return Functions.numberFormat(num);
    },

    //Добавить нули после запятой
    toFixed(num) {
      return num.toFixed(1);
    },
    openLinks(offer) {
      window.open(offer.link, "_blank");
      offer.links.forEach((link) => {
        window.open(link, "_blank");
      });
    },
  },
};
</script>
<style lang="css">
@import url(../assets/css/starzaym.scss);
</style>
<style lang="css">
.star-rating {
  width: 50%;
}
.card-info__wrapper {
  border-radius: 23.226px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  padding: 5px;

}

.card-info__rate {
  margin-left: 5px;
  margin-bottom: 15px;
}
</style>
